import { inject, Injectable, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AggregateQuoteItem, CompositeQuoteItem, EnclosureQuoteItem,
  FinishedGoodQuoteItem, IQuoteItemWithContextDecorator,
  ItemType, NettPriceItem, PartQuoteItem,
  Quote, QuoteItem } from '../quotes/shared/models'
import { ApiGatewayService } from '../../core/api-gateway.service';
import { IConfiguratorPart, IHotJobState, IQuote, IQuoteConfiguration, IQuoteFormGroup,
  IQuoteItem, IQuoteItemFormGroup, IQuoteItemRowChange,
  IQuoteItemRowToggleEvent, IQuoteListView,
  QuoteRowAction, IQuoteItemRowInletEvent,
  IQuoteItemRecordStatus,
  QuoteItemRecStatus,
  IQuoteDocumentsByCategory} from '../quotes/shared/interfaces';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Decimal } from 'decimal.js';
import { ContextManager } from '../../core/context-manager';
import { FormGroupIdDecorator } from '../../core/forms';
import { IQuoteItemPointsListConfiguration } from '../../reports/points-schedule/models';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);

  public quote: Quote = {} as Quote;

  private contextManager!: ContextManager;
  private _partnumber: number = 1;

  activeInlet: Subject<IQuoteItemRowInletEvent> = new Subject<IQuoteItemRowInletEvent>();
  quoteRowChange: Subject<IQuoteItemRowChange> = new Subject<IQuoteItemRowChange>();

  get currentContext(): FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>> {
    return this.contextManager.currentContext as FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>>;
  }

  get currentActivetContextId() {
    return this.contextManager.currentContextId;
  }

  get isBaseContext(): boolean {
    return this.contextManager.isBaseContext;
  }

  get quoteFormGroup(): FormGroup<IQuoteFormGroup> {
    return this.quote.formgroup;
  }

  get nextpartnumber(): number {
    return this._partnumber++;
  }

  resetPartNumbers(): void {
    this._partnumber = 1;
    for (let item of this.quoteFormGroup.controls.quote_items.controls) {
      if (item.controls.item_part_type.value != ItemType.NETTPRICE) {
        item.controls.item_part_number.setValue(this.nextpartnumber);
      }
    }
  }

  constructor() {}

  makeQuoteFormGroup(
    quote: IQuote | undefined = undefined
  ): FormGroup<IQuoteFormGroup> {
    let dtm_quote: Date | string = '';
    if (quote?.dtm_quote) {
      dtm_quote = new Date(quote.dtm_quote);
    }

    this.quote = new Quote(quote);
    this.contextManager = new ContextManager(
      this.quote.formgroup.controls.quote_items);

    if (this.quote.quote_item_hierarchy) {
      this.populateItems(this.quote.quote_item_hierarchy);
    }

    return this.quote.formgroup;
  }

  populateItems(items: IQuoteItem[]) {
    for (let quoteItem of items) {
      if (quoteItem.quote_item_id) {
        let type = quoteItem.item_part_type?.replace(' ', '_');

        if (type == ItemType.FINISHEDGOOD) {
          this.addFinishedGood(quoteItem);
          this.clearContext();
        }

        if (type == ItemType.COMPOSITE) {
          this.loadComposite(quoteItem);
          this.clearContext();
        }

        if (type == ItemType.PART || type == ItemType.ENCLOSURE) {
          this.loadPart(quoteItem);
          this.clearContext();
        }

        if (type == ItemType.NETTPRICE) {
          this.addNettPrice(quoteItem);
        }
      }
    }

    this.clearContext();
  }

  loadquotes(collation?: string): Observable<IQuoteListView[]> {
    let url: string = '/quotes';
    if (collation) {
      url += `?${collation}`;
    }

    return this.gateway.get<IQuoteListView[]>(url);
  }

  storeQuote(quote: IQuote) {
    return this.gateway.post<IQuote, any>('/quotes', quote);
  }

  updateQuote(quote: IQuote) {
    return this.gateway.post<IQuote, any>('/quotes/edit', quote);
  }

  getQuote(quote_id: string): Observable<IQuote> {
    return this.gateway.get<IQuote>('/quotes/' + quote_id);
  }

  loadquoteconfiguration(id: string, category:string): Observable<IQuoteItemPointsListConfiguration[]> {
    return this.gateway.get<IQuoteItemPointsListConfiguration[]>(
      `/quotes/${id}/items/configurations?c=${category}`);
  }

  makeQuoteItem(item: Partial<IConfiguratorPart>,
                insertAt: number | undefined = undefined
  ): QuoteItem {
    var quote_item: QuoteItem = {} as QuoteItem;
    let quoteItem: Partial<IQuoteItem> = {
      'item_part_id': item.id || undefined,
      'description': item.description || '',
      'price': item.part_price || 0,
      'quantity': item.quantity || 1,
      'markup': item.selectedMarkup || item.markup || 0,
      'part_is_enclosure': item.part_is_enclosure || false,
      'configurations': item.configurations || []
    }

    if (item.parts && item.parts.length) {
      quoteItem.parts = [];
      for (let itemSubpart of item.parts) {
        let subpart = {
          'quote_item_part_id': itemSubpart.id || undefined,
          'description': itemSubpart.description,
          'price': itemSubpart.part_price,
          'quantity': itemSubpart.quantity,
          'configurations': item.configurations || [],
          'markup': item.selectedMarkup || item.markup || 0,
        }

        quoteItem.parts.push(subpart);
      }
    }

    if (this.contextManager.isBaseContext &&
        item.type != ItemType.NETTPRICE) {
      console.log(item.type);
      quoteItem.item_part_number = this.nextpartnumber;
    }

    if (item.type == ItemType.PART) {
      quote_item = this.addPart(quoteItem, insertAt);
    }

    if (item.type == ItemType.NETTPRICE) {
      quote_item = this.addNettPrice(quoteItem, insertAt);
    }

    if (item.type == ItemType.COMPOSITE) {
      quote_item = this.addComposite(quoteItem, insertAt);
    }

    if (item.type == ItemType.FINISHEDGOOD) {
      quote_item = this.addFinishedGood(quoteItem, insertAt);
    }

    return quote_item;
  }

  addPart(part: Partial<IQuoteItem>,
          insertAt: number | undefined = undefined
  ): QuoteItem {
    if (part.part_is_enclosure ||
        part.item_part_type == ItemType.ENCLOSURE) {
      let item = new EnclosureQuoteItem(part);
      this.addAggregateParts(item, insertAt);
      return item;
    }
    let item = new PartQuoteItem(part);
    let fg = item.formGroup;

    if (insertAt || insertAt === 0) {
      this.currentContext.insert(insertAt, fg);
    } else {
      this.currentContext.push(fg);
    }

    return item;
  }

  loadPart(part: Partial<IQuoteItem>) {
    if (part.part_is_enclosure ||
        part.item_part_type == ItemType.ENCLOSURE) {
      let item = new EnclosureQuoteItem(part);
      this.loadAggregateParts(item);
      this.contextManager.relinquish(item.internalId);
      return item;
    }
    let item = new PartQuoteItem(part);
    let fg = item.formGroup;

    this.currentContext.push(fg);

    return item;
  }

  addNettPrice(part: Partial<IQuoteItem>,
              insertAt: number | undefined = undefined): QuoteItem {

    let item = new NettPriceItem(part);

    if (insertAt || insertAt === 0) {
      this.currentContext.insert(insertAt, item.formGroup);
    } else {
      this.currentContext.push(item.formGroup);
    }

    return item;
  }

  addComposite(composite: Partial<IQuoteItem>,
               insertAt: number|undefined = undefined) {
    let item = new CompositeQuoteItem(composite);
    this.addAggregateParts(item, insertAt);

    return item;
  }

  loadComposite(composite: Partial<IQuoteItem>) {
    let item = new CompositeQuoteItem(composite);
    this.loadAggregateParts(item);
    this.contextManager.relinquish(item.internalId);

    return item;

  }

  addFinishedGood(finishedGood: Partial<IQuoteItem>,
                  insertAt: number | undefined = undefined) {
    let item = new FinishedGoodQuoteItem(finishedGood);
    this.addAggregateParts(item, insertAt);

    return item;
  }

  editFinishedGood(item: FormGroupIdDecorator<IQuoteItemFormGroup>) {
    this.updateContext(item.id);
  }

  addAggregateParts(item: AggregateQuoteItem,
                    insertAt: number | undefined = undefined) {
    let fg = item.formGroup;
    if (insertAt || insertAt === 0) {
      this.currentContext.insert(insertAt, fg);
    } else {
      this.currentContext.push(fg);
    }

    if (fg.controls.parts) {
      this.contextManager.acquire(fg.controls.parts);

      if (item.parts?.length) {
        for (let part of item.parts) {
          if (part.item_part_type == ItemType.PART ||
              part.item_part_type == ItemType.ENCLOSURE) {
            this.addPart(part);
          }

          if (part.item_part_type == ItemType.COMPOSITE) {
            this.addComposite(part);
          }
        }
      }
    }
  }

  loadAggregateParts(item: AggregateQuoteItem,
                    insertAt: number | undefined = undefined) {
    let fg = item.formGroup;
    if (insertAt || insertAt === 0) {
      this.currentContext.insert(insertAt, fg);
    } else {
      this.currentContext.push(fg);
    }

    if (fg.controls.parts) {
      this.contextManager.acquire(fg.controls.parts);

      if (item.parts?.length) {
        for (let part of item.parts) {
          if (part.item_part_type == ItemType.PART ||
              part.item_part_type == ItemType.ENCLOSURE) {
            this.loadPart(part);
          }

          if (part.item_part_type == ItemType.COMPOSITE) {
            this.loadComposite(part);
          }
        }
      }
    }
  }

  updateHotJob(hotjob: number, quote_id: string): Observable<IHotJobState> {
    return this.gateway.post<IHotJobState, IHotJobState>('/quotes/hot_jobs', {
      'hot_job_state': hotjob,
      'quote_id': quote_id
    })
  }

  openInlet(inlet: IQuoteItemRowInletEvent) {
    console.log("opening inlet", inlet);
    this.activeInlet.next(inlet);
  }

  rowChange(event: IQuoteItemRowChange) {
    this.quoteRowChange.next(event);
  }

  delete_item(item: Partial<IQuoteItem>): Observable<IQuoteItemRecordStatus> {
    if (item.quote_item_id) {
      return this.gateway.delete('/quotes/items/' + item.quote_item_id);
    }

    return of({
      'quote_item_id': '',
      'record_status': QuoteItemRecStatus.DELETED
    });
  }

  getQuoteItemByLineNumber (
    linenumber: number): IQuoteItemWithContextDecorator | undefined {
    return this.quote.getQuoteItemAtLine(linenumber);
  }

  quoteTotal(): Decimal {
    return this.quote.total;
  }

  quoteTotalIncMarkup(): Decimal {
    return this.quote.totalIncMarkup;
  }

  quotePartTotal(item: FormGroup<IQuoteItemFormGroup>) {
    return this.quote.partTotal(item);
  }

  quotePartTotalIncMarkup(item: FormGroup<IQuoteItemFormGroup>) {
    return this.quote.partTotalIncMarkup(item);
  }

  aggregatePartTotal(item: FormGroup<IQuoteItemFormGroup>) {
    return this.quote.getTotal(this.quote.partTotal,
                               item.controls.parts);
  }

  aggregatePartTotalIncMarkup(item: FormGroup<IQuoteItemFormGroup>) {
    return this.quote.getTotal(
      this.quote.partTotalIncMarkup.bind(this.quote),
      item.controls.parts);
  }

  setCurrentLineNumber(line_number: number) {
    this.quote.setLineNumber(line_number);
  }

  disposeContext(id: number) {
    this.contextManager.relinquish(id);
  }

  updateContext(id: number) {
    let item = this.quote.getQuoteItemByInternalId(id);
    if (item) {
      this.contextManager.acquire(item.controls.parts);
    }

    return item;
  }

  reestablishBaseContext() {
    this.contextManager.makeContextBase();
  }

  clearContext() {
    this.contextManager.rescindAll();
  }

  loadFilters() {
    return this.gateway.get('/quotes/filters');
  }

  referenceExists(ref: string) {
    return this.gateway.get(`/quotes/reference/${ref}/unique`);
  }

  downloadlink(quote_id: string) {
    return `${this.gateway.endpoint}/quotes/${quote_id}/pointslists/download`;
  }

  load_quote_documents(quote_id: string): Observable<IQuoteDocumentsByCategory[]> {
    return this.gateway.get<IQuoteDocumentsByCategory[]>('/quotes/' + quote_id + '/documents');
  }

  load_quote_document(quote_id: string, doc_id: string): Observable<any> {
    return this.gateway.get<any>(`/quotes/${quote_id}/documents/${doc_id}`);
  }
}
