@if (item.value.item_part_type == 'part') {
<ng-container [formGroup]="item">
  <td class="cell action">
    <span class="action-panel">
      <span style="dispaly: inline-block; width: 18px; height: 18px;"></span>
      <button mat-icon-button type="button"
        matTooltip="Insert Below"
        (click)="toggleInlet(InletType.BELOW)">
        <mat-icon class="inlet step-over" svgIcon="step_over"
          [ngClass]="inletOpen(InletType.BELOW) ? 'insert-at-active' : 'insert-at'"></mat-icon>
      </button>
      <button mat-icon-button type="button"
        (click)="toggleInlet(InletType.ABOVE)"
        matTooltip="Insert Above">
        <mat-icon class="inlet step-out" svgIcon="step_out"
          [ngClass]="inletOpen(InletType.ABOVE) ? 'insert-at-active' : 'insert-at'"></mat-icon>
      </button>
    </span>
    <span class="action-panel line-number">
      <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
      @if (item.value.item_part_number && item.value.item_part_number > 0) {
        <span class="number part-number">{{ item.value.item_part_number }}</span>
      } @else {
        <span class="placeholder"></span>
      }
      <span class="number">{{ item.value.item_line_number }}</span>
    </span>
    <span class="action-panel">
      <span class="placeholder"></span>
      <span class="placeholder"></span>
      <button mat-icon-button type="button"
        (click)="deleteItem()">
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </td>
  <td class="cell description">
    <mat-form-field class="full-width-form-field" appearance="outline">
      <input matInput formControlName="quote_item_description" />
    </mat-form-field>
    <div class="part-configurations" formArrayName="configurations">
    @for (qitem_configuration of configurations.controls; track qitem_configuration) {
    <div class="quote-item-configuration" [formGroup]="qitem_configuration">
      <h4>{{ qitem_configuration.controls['category_name'].value }}</h4>
      <p class="category_description">{{ qitem_configuration.controls['category_description'].value }}</p>
      <div class="configuration-row" formArrayName="configuration" style="display: flex; align-items: center; height: 100%;">
        @for (qifg of configurationItems($index).controls; track qifg) {
          <div  [formGroup]="qifg" style="margin-right: .25em;">
            <mat-form-field class="full-width-form-field" appearance="outline" style="max-width: 90px;">
              <mat-label>{{ qifg.controls['configuration_item_name'].value }}</mat-label>
              <input matInput
                formControlName="quantity"
                type="number" min="0" style="width: 100%"/>
              <mat-hint>{{ qifg.controls['configuration_item_description'].value}}</mat-hint>
            </mat-form-field>
          </div>
          }
      </div>
    </div>
    }
    </div>
  </td>
  <td class="cell right-align">
    <mat-form-field class="full-width-form-field" appearance="outline">
      <input matInput type="number" step="1" min="0"
        formControlName="quantity" />
      <mat-hint *ngIf="quantity_warn" class="warn"><mat-icon class="material-symbols-outlined">warning</mat-icon> Check</mat-hint>
    </mat-form-field>
  </td>
  <td class="cell right-align">
    <mat-form-field class="full-width-form-field" appearance="outline">
      <input matInput formControlName="unit_price" />
      <mat-hint *ngIf="price_modified" class="warn"><mat-icon class="material-symbols-outlined">warning</mat-icon> Price Override</mat-hint>
    </mat-form-field>
  </td>
  <td class="cell right-align">
    <mat-form-field class="full-width-form-field" appearance="outline">
      <input type="number" [step]="markup_step" matInput formControlName="markup" />
      <mat-hint *ngIf="markup_warn" class="warn"><mat-icon class="material-symbols-outlined">warning</mat-icon> Check</mat-hint>
    </mat-form-field>
  </td>
  <td class="cell right-align total">{{  quoteItemTotal(item) }}</td>
  <td class="cell right-align total">{{  quoteItemTotalIncMarkup(item) }}</td>
</ng-container>
}

@if (item.value.item_part_type != 'part') {
<td colspan="7" style="padding: 0;">
  <table class="aggregate active" [formGroup]="item">
    <tr class="quote-item quote-item-row">
      <td class="cell action">
        <span class="action-panel">
          <button mat-icon-button type="button"
            (click)="toggleInlet(InletType.BELOW)"
            matTooltip="Insert Below">
            <mat-icon class="inlet step-over" svgIcon="step_over"
              [ngClass]="inletOpen(InletType.BELOW) ? 'insert-at-active' : 'insert-at'"></mat-icon>
          </button>
          <button mat-icon-button type="button"
            (click)="toggleInlet(InletType.ABOVE)"
            matTooltip="Insert Above">
            <mat-icon class="inlet step-out" svgIcon="step_out"
             [ngClass]="inletOpen(InletType.ABOVE) ? 'insert-at-active' : 'insert-at'"></mat-icon>
          </button>
          <button mat-icon-button type="button"
            matTooltip="Insert Into"
            (click)="toggleInlet(InletType.INTO)">
            <mat-icon class="inlet step-into" svgIcon="step_into"
              [ngClass]="inletOpen(InletType.INTO) ? 'insert-at-active' : 'insert-at'">
            </mat-icon>
          </button>
        </span>
        <span class="action-panel line-number">
          <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>
          @if (item.value.item_part_number && item.value.item_part_number > 0) {
            <span class="number part-number">{{ item.value.item_part_number }}</span>
          } @else {
            <span class="placeholder"></span>
          }
          <span class="number">{{ item.value.item_line_number }}</span>
        </span>
        <span class="action-panel">
          <span class="placeholder"></span>
          <button mat-icon-button type="button"
            (click)="copyItem()"
            matTooltip="Copy"
            *ngIf="canCopy()"><mat-icon>file_copy</mat-icon>
          </button>
          <button mat-icon-button
            type="button"
            matTooltip="Delete"
            (click)="deleteItem()">
            <mat-icon>delete</mat-icon>
          </button>
        </span>
      </td>
      <td class="cell description">
        <mat-form-field class="full-width-form-field" appearance="outline">
          <input matInput formControlName="quote_item_description" />
        </mat-form-field>
      </td>
      @if (item.value.item_part_type != 'enclosure') {
      <td class="cell right-align"></td>
      <td class="cell right-align"></td>
      <td class="cell right-align"></td>
      <td class="cell right-align total">{{ quoteItemAggTotal(item) }}</td>
      <td class="cell right-align">{{ quoteItemAggTotalIncMarkup(item) }}</td>
      } @else {
      <td class="cell right-align">
        <mat-form-field class="full-width-form-field" appearance="outline">
          <input type="number" step="1" matInput formControlName="quantity" />
          <mat-hint *ngIf="quantity_warn" class="warn"><mat-icon class="material-symbols-outlined">warning</mat-icon> Check</mat-hint>
        </mat-form-field>
      </td>
      <td class="cell right-align">
        <mat-form-field class="full-width-form-field" appearance="outline">
          <input matInput formControlName="unit_price" />
          <mat-hint *ngIf="price_modified" class="warn"><mat-icon class="material-symbols-outlined">warning</mat-icon> Price Override</mat-hint>
        </mat-form-field>
      </td>
      <td class="cell right-align">
        <mat-form-field class="full-width-form-field" appearance="outline">
          <input type="number" [step]="markup_step" matInput formControlName="markup" />
          <mat-hint *ngIf="markup_warn" class="warn"><mat-icon class="material-symbols-outlined">warning</mat-icon> Check</mat-hint>
        </mat-form-field>
      </td>
      <td class="cell right-align total">{{  quoteItemTotal(item) }}</td>
      <td class="cell right-align total">{{  quoteItemTotalIncMarkup(item) }}</td>
      }
    </tr>
    <tbody cdkDropList
      (cdkDropListDropped)="drop($event)"
      class="finished-good-drag-boundary">
    @for (fg_item of getcontrols(item.controls.parts); track fg_item; let idx = $index) {
        <tr [item]="fg_item"
          quote-item-row
          cdkDrag cdkDragBoundary=".finished-good-drag-boundary"
          class="quote-item-row">
        </tr>
    }
    </tbody>
  </table>
</td>
}
