import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface User {
  username: string;
  first_name: string;
  surname: string;
  password: string;
  email: string;
  group: string;
  signature_image: string;
}

export interface UserGroup {
  group_name: string;
  description: string;
}

export interface UserFormGroup {
  username: FormControl<string>;
  first_name: FormControl<string>;
  surname: FormControl<string>;
  password: FormControl<string>;
  email: FormControl<string>;
  group: FormControl<string>;
  signature_image: FormControl<string>;
}

export class User implements User {
  constructor(user?: User) {
    this.username = user?.username || '';
    this.first_name = user?.first_name || '';
    this.surname = user?.surname || '';
    this.password = user?.password || '';
    this.email = user?.email || '';
    this.group = user?.group || '';
  }

  get formGroup(): FormGroup<UserFormGroup> {
    return new FormGroup<UserFormGroup>({
      username: new FormControl(this.username,
                                { nonNullable: true,
                                  validators: [Validators.required]}),
      first_name: new FormControl(this.first_name,
                                  { nonNullable: true,
                                    validators: [Validators.required]}),
      surname: new FormControl(this.surname,
                               { nonNullable: true,
                                 validators: [Validators.required]}),
      password: new FormControl(this.password,
                                { nonNullable: true,
                                  validators: [Validators.required]}),
      email: new FormControl(this.email,
                             { nonNullable: true,
                               validators: [Validators.required, Validators.email]}),
      group: new FormControl(this.group,
                             { nonNullable: true,
                               validators: [Validators.required]}),
      signature_image: new FormControl('', {nonNullable: true}),
    })
  }
}
