import { Component, effect, ElementRef, inject, OnDestroy, OnInit, signal, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ConfiguratorComponent, IToolbarEvent } from '../../configurator/configurator.component';
import { CommonModule } from '@angular/common';
import { QuoteService } from '../../shared/quote.service';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CdkDropList, DragDropModule, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { Decimal } from "decimal.js";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { SelectcustomerComponent } from '../../../controls/selectcustomer/selectcustomer.component';
import { ItemType, QuoteItem } from '../shared/models';
import { ActivatedRoute } from '@angular/router';
import { IQuoteItemFormGroup, IQuoteItem, IQuoteFormGroup, IConfiguratorPart,
  IConfiguratorAggContextLeaseRequest, IQuoteItemRowToggleEvent,
  IQuote, QuoteRowAction,
  InletType,
  IQuoteItemRowInletEvent,
  IQuoteItemRowChange} from '../shared/interfaces';
import { Subscription } from 'rxjs';
import { FormGroupIdDecorator } from '../../../core/forms';
import { QuoteItemRowComponent } from './rows/quote-item-row/quote-item-row.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QuoteNettPriceRowComponent } from './rows/quote-nett-price-row/quote-nett-price-row.component';
import { DomSanitizer } from '@angular/platform-browser';

export const TULL_DATE_FORMAT = {
    parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-quote-edit',
  standalone: true,
  imports: [
    CommonModule,
    ConfiguratorComponent,
    ReactiveFormsModule,
    MatButtonModule,
    MatDatepickerModule,
    DragDropModule,
    CdkDropList,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SelectcustomerComponent,
    QuoteItemRowComponent,
    QuoteNettPriceRowComponent,
    MatProgressSpinnerModule
  ],
  providers: [
    provideMomentDateAdapter(TULL_DATE_FORMAT, {useUtc: true}),
  ],
  templateUrl: './quote-edit.component.html',
  styleUrl: './quote-edit.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class QuoteEditComponent implements OnInit, OnDestroy {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private quoteService: QuoteService = inject(QuoteService);

  private insertionPoint: number = 0;
  private insertion_direction: InletType = InletType.NONE;
  private insertionArrayIndex: number | undefined = undefined;

  modified: boolean = false;

  inFinishedGoodContext: boolean = false;
  inCompositeContext: boolean = false;
  inEnclosureContext: boolean = false;

  quoteFormGroup!: FormGroup<IQuoteFormGroup>;
  grandTotal: Decimal = new Decimal(0);
  grandTotalIncMarkup: Decimal = new Decimal(0);
  private clipboard!: Partial<IQuoteItem>;

  resetConfigurator = signal(false);

  private activeCompositeInternalId: number = 0;

  private activeEnclosureInternalId: number = 0;
  leaseContext!: IConfiguratorAggContextLeaseRequest;
  toolbaraction!: IToolbarEvent[];

  buttonText: string = 'Add';
  editMode: boolean = false;
  activeFinishedGoodDescription: string = '';

  activeInletSubscription: Subscription;
  rowChangeSubscription: Subscription;

  get canCopy(): boolean {
    return false;
  }

  get canAddFinishedGood(): boolean {
    return false;
  }

  get canAddComposite(): boolean {
    return false;
  }

  get canAddEnclosure(): boolean {
    return false;
  }

  getquoterowclass(item: Partial<IQuoteItem>): string[] {
    let css_class = [`${item.item_part_type}-row`]
    if (item.item_part_type != ItemType.PART) {
      css_class.push('aggregate-row');
    }

    return css_class;
  }

  checkingRef: boolean = false;

  @ViewChild('quoteItemContainer', {read: ViewContainerRef}) quoteItemContainer!: ViewContainerRef;
  @ViewChild('dragBoundary') dragBoundary!: ElementRef;

  get quoteItems(): FormArray<FormGroup<IQuoteItemFormGroup>> {
    return this.quoteFormGroup.controls['quote_items'] as FormArray<FormGroup<IQuoteItemFormGroup>>;
  }

  configurations(qitemidx: number): FormArray<FormGroup<any>> {
    return this.quoteItems.at(qitemidx).controls.configurations  as FormArray<FormGroup<any>>;
  }

  configurationItems(items: any): FormArray<FormGroup<any>> {
    return items as FormArray<FormGroup<any>>;
  }

  get quote_ref(): FormControl<string|null> {
    return this.quoteFormGroup.controls.quote_ref;
  }

  constructor() {
    const iconregistry = inject(MatIconRegistry);
    const sanitizer = inject(DomSanitizer);

    iconregistry.addSvgIcon('step_into', sanitizer.bypassSecurityTrustResourceUrl('/step-into.svg'));
    iconregistry.addSvgIcon('step_out', sanitizer.bypassSecurityTrustResourceUrl('/step-out.svg'));
    iconregistry.addSvgIcon('step_over', sanitizer.bypassSecurityTrustResourceUrl('/step-over.svg'));
    effect(() => {
      if (!this.resetConfigurator()) {
        if (this.updateInsertion) {
          this.updateInsertion();
        }
        this.updateInsertion = undefined;
      }
    });

    this.activeInletSubscription =
      this.quoteService.activeInlet.subscribe(
      (inlet: IQuoteItemRowInletEvent) => {
        let isBaseContext: boolean = inlet.internal_id == 0;
        let isAggregate: boolean = false;
        this.insertion_direction = inlet.direction;

        if (inlet.checked) {
          this.insertionPoint = inlet.line_number;
          let _item = this.quoteService.quote.getQuoteItemByInternalId(inlet.internal_id);
          if (_item && _item.value.item_part_type &&
              ![ItemType.PART, ItemType.NETTPRICE].includes(
                _item.value.item_part_type as ItemType)) {
            isAggregate = true;
          }

          if (isBaseContext) {
            this.quoteService.reestablishBaseContext();
            this.resetConfigurator.set(true);
          }

          if (_item && isAggregate) {
            this.editAggregate(_item);
          }

          this.setInsertionArrayIndex();
          this.updateToolbar();
        } else {
          this.insertionPoint = 0;
          this.insertion_direction = InletType.NONE;
          this.insertionArrayIndex = undefined;
        }
      }
    )

    this.rowChangeSubscription =
      this.quoteService.quoteRowChange.subscribe((_e: IQuoteItemRowChange) => {
      if (_e.action = QuoteRowAction.DELETE) {
        this.on_item_delete(_e.source);
      };
    });
  }

  ngOnInit(): void {
    Decimal.set({precision: 20});
    let quote_id = this.route.snapshot.params['id'] || undefined;

    if (quote_id) {
      this.quoteService.getQuote(quote_id).subscribe(quote => {
        this.loadQuote(quote);
      });
    } else {
      this.loadQuote();
    }
  }

  loadQuote(quote: IQuote|undefined = undefined) {
    this.quoteFormGroup = this.quoteService.makeQuoteFormGroup(quote);
    console.log(this.quoteFormGroup.value);
    this.updateTotal();
    this.updateTotalsMonitor();
    if (quote) {
      this.setEditMode();
    }
    this._prepare();
  }

  setInsertionArrayIndex() {
    console.log("insertion point is: ", this.insertionPoint);
    let ctx = this.quoteService.currentContext;
    let idx = 0;
    let found: boolean = false;

    for (let _item of ctx.controls) {
      if (_item.value.item_line_number == this.insertionPoint) {
        found = true;
        break;
      }

      idx++;
    }

    if (this.insertion_direction == InletType.ABOVE) {
      this.insertionArrayIndex = idx;
    }

    if (this.insertion_direction == InletType.BELOW) {
      this.insertionArrayIndex = ++idx;
    }
  }

  updateToolbar() {
    let buttons = [
      {
        'button': 'nett_price',
        'disable': true,
      },
      {
        'button': 'finished_good',
        'disable': true,
      },
      {
        'button': 'paste',
        'disable': true
      }
    ]

    if (this.quoteService.isBaseContext) {
      buttons.map(x => {x.disable = false});
      this.toolbaraction = buttons;
    } else {
      this.toolbaraction = buttons;
    }
  }

  private _prepare() {
    let timeoutid: ReturnType<typeof setTimeout>|null = null;
    this.quoteFormGroup.controls.quote_ref.valueChanges.subscribe(
      val => {
        if (!val) return;
        if (val.length <= 5) return;

        if (timeoutid) {
          clearTimeout(timeoutid);
        }

        timeoutid = setTimeout(
          (ref: string) => {
            this.checkingRef = true;
            this.quoteService.referenceExists(ref).subscribe({
              next: (msg: any) => {
                if (msg.reference_exists) {
                  let control = this.quoteFormGroup.controls.quote_ref;
                  control.setErrors({
                    'reference': true
                  });
                }
                timeoutid = null;
                this.checkingRef = false;
              }
            });
          },
          1000,
          val
        )
      }
    );

    this.quoteFormGroup.valueChanges.subscribe(_ => {
      this.modified = true;
    });
  }

  handlePartSelect(part: IConfiguratorPart) {
    if ((part.type == ItemType.NETTPRICE || part.type == ItemType.FINISHEDGOOD) && !this.quoteService.isBaseContext) {
      return;
    }

    let quoteItem: QuoteItem = this.quoteService.makeQuoteItem(
      part,
      this.insertionArrayIndex);
    let curLineNumber = this.onItemReorder(this.quoteItems);
    this.quoteService.setCurrentLineNumber(curLineNumber);

    this.handlePartContext(quoteItem);
  }

  handlePartContext(quoteItem: QuoteItem) {
    // @TODO: Need a separate interface to indicate an aggregate
    if (![ItemType.PART, ItemType.NETTPRICE].includes(quoteItem.itemType)) {
      this.leaseContext = {
        'internal_id': quoteItem.internalId,
        'aggregate_type': quoteItem.itemType,
        'subscription': this.quoteService.quote.getQuoteItemByInternalId(
          quoteItem.internalId)?.controls.quote_item_description.valueChanges
      }

      this.addAggregate();
    }
  }

  addAggregate() {
    this.activeCompositeInternalId = this.quoteService.currentActivetContextId;
    this.updateToolbar();
  }

  editAggregate(item: FormGroup<IQuoteItemFormGroup>,
                editButton: boolean = false) {
    if (item instanceof FormGroupIdDecorator) {
      this.leaseContext = {
        'internal_id': item.id,
        'aggregate_type': item.value.item_part_type
      }
      this.quoteService.updateContext(item.id);
    }

    if (!this.inCompositeContext) {
      this.inCompositeContext = true;
    }
    if (editButton) {
      this.deactivateInsertionPoint();
    }
  }

  // @TODO: rename this function as it handles all aggregates
  destroyCompositeContext(id?: number) {
    let compositeInternalId = id || this.activeCompositeInternalId || this.activeEnclosureInternalId;
    if (this.insertionPoint > 0) {
      let item = this.quoteService.getQuoteItemByLineNumber(this.insertionPoint);

      if (compositeInternalId == item?.parentcontext) {
        this.deactivateInsertionPoint();
      }
    }

    this.quoteService.disposeContext(compositeInternalId);
    this.inCompositeContext = false;
    this.activeCompositeInternalId = 0;
    this.updateToolbar();
  }

  updateInsertion: any = undefined;
  handleOpenInlet(linenumber?: number) {
    console.log("handling inlet");
  }

  deactivateInsertionPoint(inlet:number|undefined=undefined) {
    console.log("deactivate inlet");
  }

  isInsertionPointActive(line_number: number | undefined): boolean {
    return false;
  }

  canPaste(item: any): boolean {
    return this.inFinishedGoodContext &&
      !this.inCompositeContext &&
      this.clipboard != undefined &&
      this.clipboard.item_part_id != item.item_part_id &&
      item.quote_item_description == '' &&
      !item.parts.length
  }

  getcontrols(items: FormArray<FormGroup<IQuoteItemFormGroup>>|undefined) {
    if (items) {
      return items.controls;
    }

    return [];
  }

  onItemReorder(quoteItems: FormArray<FormGroup<IQuoteItemFormGroup>>, curLineNumber: number = 1) {
    for (let item of quoteItems.controls) {
      item.controls.item_line_number.setValue(curLineNumber++);
      if (item.controls.parts?.length) {
        curLineNumber = this.onItemReorder(item.controls.parts, curLineNumber);
      }
    }
    this.quoteService.resetPartNumbers();
    return curLineNumber;
  }

  setEditMode() {
    this.editMode = true;
    this.buttonText = "Update";
  }

  updateTotalsMonitor() {
    this.quoteFormGroup.valueChanges.subscribe((_) => {
      this.updateTotal();
    });

  }

  updateTotal() {
    this.grandTotal = this.quoteService.quoteTotal();
    this.grandTotalIncMarkup = this.quoteService.quoteTotalIncMarkup();
  }

  copyItem(item: FormGroup<IQuoteItemFormGroup>) {
    this.clipboard = item.value as IQuoteItem;
  }

  pasteItem(duplicate: FormGroup<IQuoteItemFormGroup>) {
    if (duplicate.value.item_part_id == this.clipboard.item_part_id) {
      return;
    }

    duplicate.controls.quote_item_description.setValue(
      this.clipboard.quote_item_description || '');

    if (this.clipboard.parts && this.clipboard.parts.length) {
      this.cloneParts(this.clipboard.parts);
    }
  }

  cloneParts(partsFrom: Partial<IQuoteItem>[]) {
    for (let part of partsFrom) {
      if (part.item_part_type == ItemType.PART) {
        let new_part = {
          "description": part.quote_item_description,
          "id": part.item_part_id,
          "quantity": part.quantity,
          "part_price": part.unit_price,
          "markup": part.markup,
          "type": ItemType.PART
        }

        this.quoteService.makeQuoteItem(new_part);
      }

      if (part.item_part_type == ItemType.COMPOSITE) {
        let new_part = {
          "description": part.quote_item_description,
          "id": part.item_part_id,
          "type": ItemType.COMPOSITE
        }

        this.quoteService.makeQuoteItem(new_part);
        if (part.parts?.length) {
          this.cloneParts(part.parts);
        }

        this.destroyCompositeContext();
      }
    }
  }

  destroyContext() {
    console.log("destroying context");
    this.quoteService.clearContext();
  }

  on_item_delete(linenumber: number) {
    let curLineNumber = this.onItemReorder(this.quoteItems);
    this.quoteService.setCurrentLineNumber(curLineNumber);
    this.updateTotal();
  }

  drop(event: CdkDragDrop<any>, items: FormArray<FormGroup<IQuoteItemFormGroup>> | undefined) {
    if (items) {
      let item = items.at(event.previousIndex);
      let sourceLinenumber = item.controls.item_line_number.value;
      let targetLinenumber = items.at(event.currentIndex).controls.item_line_number.value;

      items.removeAt(event.previousIndex);
      items.insert(event.currentIndex, item);
      this.onItemReorder(this.quoteItems);
      this.quoteService.rowChange({
        action: QuoteRowAction.DROP,
        activeInlet: this.insertionPoint,
        source: sourceLinenumber,
        target: targetLinenumber
      })
    }
  }

  onSubmit() {
    console.log(this.quoteFormGroup.value);
    this.quoteFormGroup.markAllAsTouched();
    if (this.quoteFormGroup.valid) {
      if (this.editMode) {
        this.quoteService.updateQuote(this.quoteFormGroup.value as IQuote).subscribe(quote => {
          this.modified = false;
          this.loadQuote(quote);
        });
      } else {
        this.quoteService.storeQuote(this.quoteFormGroup.value as IQuote).subscribe(quote => {
          this.modified = false;
          this.loadQuote(quote);
        });
      }
    }
  }

  ngOnDestroy(): void {
      this.quoteService.setCurrentLineNumber(1);
      this.activeInletSubscription.unsubscribe();
      this.rowChangeSubscription.unsubscribe();
  }

  checkQuoteRef(e: KeyboardEvent) {
    if (e.key.toLowerCase() == ' ') return false;
    if (e.key.toLowerCase() == 'backspace') return true;

    let ref = this.quoteFormGroup.controls.quote_ref.value;
    if (!ref || (ref.length < 5)) {
      let _code = e.key.charCodeAt(0);
      if (_code < 48 || _code > 57) {
        return false;
      }
    }

    return true;
  }
}
